<template>
  <b-row>
    <b-col cols="12">
      <tabela-repeticao
        v-model="valueIn"
        :colunas="cnaeColunas"
        :validar="validar"
        :somente-visualizacao="false"
        class="cnae-tabela"
      >
        <template #visualizacao="{slotScope: {valor}}">
          {{ valor }}
        </template>

        <template #edicao="{slotScope: {item, key}}">
          <validation-provider
            #default="{ errors }"
            name="Cnae"
            :rules="valueIn.length > 0 && item.idIbge? 'required' : ''"
          >
            <b-input
              v-model="item[key]"
              :disabled="key==='descricao'"
              :class="{'is-invalid' : item.erro && item.erro[key]}"
              :type="key==='descricao' ? 'text' : 'number'"
              @blur="buscarDescricao(item, key)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </template>

      </tabela-repeticao>
    </b-col>
  </b-row>
</template>

  <style lang="scss">
  td.ie-select {
    max-width: 8rem !important;
  }

  .cnae-tabela tbody tr td:first-child{
    width: 14rem;
  }
  </style>

<script>
import { ValidationProvider } from 'vee-validate'
import api from './api'

export default {
  name: 'CnaeTabela',
  components: {
    ValidationProvider,
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cnaeColunas: [
        { key: 'idIbge', label: 'Código Cnae' },
        { key: 'descricao', label: 'Descrição' },
      ],
      valueIn: [],
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.valueIn = value
      }
    },
    valueIn(value, oldValue) {
      if (value !== oldValue) {
        this.$emit('input', value)
      }
    },
  },
  created() {
    this.valueIn = this.value
  },
  methods: {
    async buscarDescricao(item, key) {
      if (key === 'idIbge' && item.idIbge) {
        const cnae = await api.buscarCnae(item.idIbge)
        const desc = cnae.data.length === 0 ? null : cnae.data[0].descricao
        if (desc == null) {
          this.$set(item, 'erro', {})
          this.$set(item.erro, key, desc)
        } else {
          this.$set(item, 'erro', null)
        }
        this.$set(item, 'descricao', desc)
        this.$emit('input', this.valueIn)
        return true
      }
      this.$set(item, 'descricao', null)
      return false
    },
    async validar(item) {
      await this.buscarDescricao(item, 'idIbge')
      return (!item.erro && item.idIbge !== undefined && item.idIbge !== '' && item.descricao !== undefined && item.descricao !== '')
    },
  },
}
</script>
