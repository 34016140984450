import axios from '@axios'

export default {

  buscarCnae(codigo) {
    return axios.get(`v2/cnae/subclasses/${codigo}`,
      {
        url: `v2/cnae/subclasses/${codigo}`,
        baseURL: 'https://servicodados.ibge.gov.br/api',
        method: 'GET',
      })
  },

}
