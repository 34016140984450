var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('tabela-repeticao',{staticClass:"cnae-tabela",attrs:{"colunas":_vm.cnaeColunas,"validar":_vm.validar,"somente-visualizacao":false},scopedSlots:_vm._u([{key:"visualizacao",fn:function(ref){
var valor = ref.slotScope.valor;
return [_vm._v(" "+_vm._s(valor)+" ")]}},{key:"edicao",fn:function(ref){
var ref_slotScope = ref.slotScope;
var item = ref_slotScope.item;
var key = ref_slotScope.key;
return [_c('validation-provider',{attrs:{"name":"Cnae","rules":_vm.valueIn.length > 0 && item.idIbge? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{class:{'is-invalid' : item.erro && item.erro[key]},attrs:{"disabled":key==='descricao',"type":key==='descricao' ? 'text' : 'number'},on:{"blur":function($event){return _vm.buscarDescricao(item, key)}},model:{value:(item[key]),callback:function ($$v) {_vm.$set(item, key, $$v)},expression:"item[key]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}]),model:{value:(_vm.valueIn),callback:function ($$v) {_vm.valueIn=$$v},expression:"valueIn"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }